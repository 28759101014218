var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"max-width":"100vw"}},[_c('v-navigation-drawer',{staticStyle:{"top":"0px","max-height":"100vh!important"},attrs:{"right":"","clipped":_vm.$vuetify.breakpoint.lgAndUp,"app":""},model:{value:(_vm.filters),callback:function ($$v) {_vm.filters=$$v},expression:"filters"}},[_c('filterCollections',{attrs:{"company":_vm.company},on:{"filtersCollection":_vm.filtersCollection}})],1),_c('v-toolbar',{staticClass:"px-4 pt-2",attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Modificar Cobranza "+_vm._s(_vm.collections.length)+" "),_c('br'),(_vm.filterStorage())?_c('span',{staticStyle:{"font-size":"12px","color":"grey","position":"absolute"}},[_vm._v("Mes Actual")]):_vm._e()]),(_vm.filterStorageLength== undefined)?_c('v-btn',{staticClass:"ml-6 pt-2",attrs:{"icon":""}},[_c('v-icon',{on:{"click":function($event){return _vm.openFilter()}}},[_vm._v("mdi-filter")])],1):_c('v-btn',{staticClass:"ml-6",attrs:{"icon":""}},[_c('v-badge',{attrs:{"overlap":"","color":"primary"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_vm._v(" "+_vm._s(_vm.filterStorageLength)+" ")]},proxy:true}])},[_c('v-icon',{on:{"click":function($event){return _vm.openFilter()}}},[_vm._v("mdi-filter")])],1)],1),_c('v-spacer'),_c('v-btn',{staticClass:"elevation-0",attrs:{"color":"primary","rounded":""},on:{"click":function($event){_vm.sheet=true}}},[_vm._v("GUARDAR")])],1),(_vm.showTable && _vm.tableGraph)?_c('v-data-table',{staticClass:"elevation-0 pt-6 px-6 pb-4",attrs:{"height":"600","fixed-header":"","footer-props":{'items-per-page-options':[15, 30, 50, 100]},"options":_vm.options,"server-items-length":_vm.totalCollections,"loading":_vm.loading,"item-class":_vm.itemRowBackground,"headers":_vm.headers,"items":_vm.collections},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.macro",fn:function(ref){
var item = ref.item;
return [(item.macro==true)?_c('v-icon',{on:{"click":function($event){return _vm.macroCapture(item.id,0)}}},[_vm._v("mdi-check")]):_c('v-icon',{on:{"click":function($event){return _vm.macroCapture(item.id,1)}}},[_vm._v("mdi-close")])]}},{key:"item.methods",fn:function(ref){
var item = ref.item;
return _vm._l((item.methods),function(method,index){return _c('div',{key:index,staticClass:"pt-6"},[_c('v-autocomplete',{attrs:{"clearable":"","items":_vm.paymentMethodLists,"label":"Metodo de Pago","item-text":"method","item-value":"id"},model:{value:(method.method.id),callback:function ($$v) {_vm.$set(method.method, "id", $$v)},expression:"method.method.id"}},[_c('template',{slot:"no-data"},[_c('div',{staticClass:"px-4 py-1"},[_vm._v("No existen metodos relacionadas.")])])],2),_c('v-text-field',{attrs:{"label":"Monto"},model:{value:(method.amount),callback:function ($$v) {_vm.$set(method, "amount", $$v)},expression:"method.amount"}})],1)})}},{key:"item.checkbox",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{model:{value:(item.checkbox),callback:function ($$v) {_vm.$set(item, "checkbox", $$v)},expression:"item.checkbox"}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Factura"},model:{value:(item.date),callback:function ($$v) {_vm.$set(item, "date", $$v)},expression:"item.date"}})]}},{key:"item.invoice",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Factura"},model:{value:(item.invoice),callback:function ($$v) {_vm.$set(item, "invoice", $$v)},expression:"item.invoice"}})]}},{key:"item.remission",fn:function(ref){
var item = ref.item;
return [_c('v-text-field',{attrs:{"label":"Remisión"},model:{value:(item.remission),callback:function ($$v) {_vm.$set(item, "remission", $$v)},expression:"item.remission"}})]}},{key:"no-data",fn:function(){return [_vm._v(" No existen registros de cobranza aún ")]},proxy:true}],null,true)}):_vm._e(),_c('div',{staticClass:"text-center"},[_c('v-bottom-sheet',{attrs:{"inset":""},model:{value:(_vm.sheet),callback:function ($$v) {_vm.sheet=$$v},expression:"sheet"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"150px"}},[_c('div',{staticClass:"pt-6"},[_vm._v(" ¿Segura que deseas modificar estas cobranzas? ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"error","disabled":_vm.gris},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Si ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"text":"","color":"grey"},on:{"click":function($event){return _vm.cancel()}}},[_vm._v(" No ")])],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.show),callback:function ($$v) {_vm.$set(_vm.snackbar, "show", $$v)},expression:"snackbar.show"}},[_vm._v(" "+_vm._s(_vm.snackbar.message)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }